



















































































import {
  defineComponent, reactive, computed, watch, watchEffect,
} from '@vue/composition-api';
import forEach from 'lodash/forEach';
import pickBy from 'lodash/pickBy';
import { $DYNAMIC_REPORT_CONFIGS } from '../composables/config';
import { formatData } from '../utils';

export default defineComponent({
  props: {
    data: {
      required: true,
      type: Object,
    },
  },
  setup(props) {
    const apiData = props.data;
    const collectionKeys = [
      'profitability_ratio',
      'return_ratio',
      'liquidity_ratio',
      'efficiency_ratio',
      'solvency_ratio',
    ];
    const seriesKeys = reactive([]);
    const fieldKeys = reactive({});
    const dataSet = reactive({});
    const configs = computed(
      () => $DYNAMIC_REPORT_CONFIGS.value.filter(item => collectionKeys.includes(item.name)),
    );
    const reverseSpectrum = [
      'ar_days',
      'inventory_days',
      'trade_cycle',
    ];

    const collectionConfigs = reactive({});

    watchEffect(
      () => {
        const rawSeriesKeys = configs.value[0].series.filter(item => item.display).map(item => item.name);
        const anualReportKeys = Object.keys(apiData[collectionKeys[0]]).filter(item => item.match(/^\d+$/));
        rawSeriesKeys.splice(rawSeriesKeys.indexOf('anual_report'), 1, ...anualReportKeys);
        seriesKeys.splice(0, seriesKeys.length, ...rawSeriesKeys);


        collectionKeys.forEach(collection => {
          Object.assign(collectionConfigs, { [collection]: configs.value.find(item => item.name === collection) });

          const collectionFieldKeys = collectionConfigs[collection].fields.filter(item => item.display).map(item => item.name);
          Object.assign(fieldKeys, { [collection]: collectionFieldKeys });
        });
      },
    );

    /** Methods */
    const fieldConfigs = (collectionName: string, fieldName: string) => {
      const fieldConfig = collectionConfigs[collectionName].fields.find(item => item.name === fieldName);
      return fieldConfig;
    };

    return {
      title: 'อัตราส่วนทางการเงิน (Financial Ratios)',
      reverseSpectrum,
      formatData,
      configs,
      collectionKeys,
      seriesKeys,
      fieldKeys,
      fieldConfigs,
      apiData,
      collectionConfigs,
    };
  },
});
